<!-- Mto particular de Compañías Sucursales -->

<template>
  <div class="cias_M" v-if="loaded">
    <dualTemplate
      :dualTipo="sync? '':'modal'"
      :modal="modal"
       persistent>
      
        <template v-slot:controles="{}">

          <div>
    
            <!-- Cabecera -->
            <div class="cabecera">
              <baseHeader
                :cfg="$cfe.headers.mto"
                :titulo='$store.state[storeName].titulo'
                @onEvent="eventHeader">
              </baseHeader>
            </div>

            <v-sheet :elevation="4">
              <div class="contenedor" style="width:1200px">

                <!-- <div v-html="info"></div> -->

                <!-- Botones Mto -->
                <div class="pt-2 d-flex justify-left" style="justify-content:space-between;width:360px">
                  
                  <!-- Mto -->            
                  <baseBtraMto class="conflex"
                    v-if="!readOnly"
                    :perm="permMto"
                    :modulo="btMtoCfg"
                    :estado="estado"
                    @onEvent="execAccion">        
                  </baseBtraMto>
               
                  <!-- Extra -->            
                  <div class="conflex" style="margin-left: 50px">                         
                    <baseBtraExtra class="conflex"           
                      :permExtra="permExtra"
                      :modulo="btExtCfg"           
                      @onEvent="execAccion">             
                    </baseBtraExtra>                  
                  </div>
                             
                </div>

                <!-- Controles del Mto -->
                <div class="conflex" style="padding-top:10px">
                  <div class="columna" style="width:59%">

                    <div class="cab">DATOS GENERALES</div>
                    <v-sheet v-bind="$cfg.styles.marco">
                      <uiText
                        styleInput="font-weight: bold;"
                        v-model="ct.name[2]"
                        :label="ct.name[1]"
                        :disabled="noEdit"
                        uCase>                      
                      </uiText>

                      <uiText                        
                        v-model="ct.dire[2]"
                        :label="ct.dire[1]"
                        :disabled="noEdit">                      
                      </uiText>

                      <ctrlF               
                        :ct="ct.loc"
                        :edicion="!noEdit">                        
                      </ctrlF>

                      <div class="conflex">
                        <uiText  
                          style="width:50%"
                          v-model="ct.cp[2]"
                          :label="ct.cp[1]"                        
                          :disabled="noEdit">                      
                        </uiText>

                        <uiText            
                          style="width:50%"           
                          v-model="ct.cif[2]"
                          :label="ct.cif[1]"
                          :disabled="noEdit"
                          format="cif">                      
                        </uiText>
                      </div>
                    </v-sheet>

                    <div class="cab">DATOS COMPAÑÍA</div>
                    <v-sheet v-bind="$cfg.styles.marco">          
                      <div class="conflex"> 
                        <uiText                          
                          v-model="ct.cont1[2]"
                          :label="ct.cont1[1]"
                          :disabled="noEdit"
                        ></uiText>

                        <uiText                        
                          v-model="ct.cont2[2]"
                          :label="ct.cont2[1]"
                          :disabled="noEdit"
                        ></uiText>
                      
                        <uiText
                          v-model="ct.cont3[2]"
                          :label="ct.cont3[1]"
                          :disabled="noEdit"
                        ></uiText>
                      </div>

                      <div class="conflex"> 
                        <uiText
                          v-model="ct.email1[2]"
                          :label="ct.email1[1]"
                          :disabled="noEdit"
                        ></uiText>

                        <uiText
                          v-model="ct.email2[2]"
                          :label="ct.email2[1]"
                          :disabled="noEdit"
                        ></uiText>
                      </div>
                      
                      <uiText
                        v-model="ct.admoncont[2]"
                        :label="ct.admoncont[1]"
                        :disabled="noEdit"
                      ></uiText>

                      <div class="conflex">                
                        <uiText
                          v-model="ct.admontlf1[2]"
                          :label="ct.admontlf1[1]"
                          :disabled="noEdit"
                        ></uiText>
                      
                        <uiText
                          v-model="ct.admonfax1[2]"
                          :label="ct.admonfax1[1]"
                          :disabled="noEdit"
                        ></uiText>
                          
                        <uiText
                          v-model="ct.admonemail[2]"
                          :label="ct.admonemail[1]"
                          :disabled="noEdit"
                        ></uiText>
                      </div>

                      <div class="conflex">
                        <uiText
                          v-model="ct.banco[2]"
                          :label="ct.banco[1]"
                          :disabled="noEdit"
                        ></uiText>

                        <uiText
                          v-model="ct.cuenta[2]"
                          :label="ct.cuenta[1]"
                          format="banco"
                          :disabled="noEdit"
                        ></uiText>
                      </div>
                    </v-sheet>

                    <uiObs3                       
                      :record="record"
                      :schema="ct"
                      :schemaComponente="ct.obs"
                      :edicion="!noEdit">
                    </uiObs3>                    

                  </div>

                  <div class="columna" style="width:40%">
                    <div class="cab">CENTRAL</div>
                    <v-sheet v-bind="$cfg.styles.marco">       
                      <ctrlF                        
                        :ct="ct.ciac"
                        :edicion="!noEdit">
                      </ctrlF>
                    </v-sheet>

                    <div class="cab">CLASIFICACIÓN</div>
                    <v-sheet v-bind="$cfg.styles.marco">
                      <div class="conflex">
                        <v-select
                          :items="itemsSitu"
                          :disabled="noEdit"
                          item-value="id"
                          item-text="label"
                          v-bind="$select"
                          v-model="ct.situ[2]"
                          :label="ct.situ[1]">
                        </v-select>

                        <uiText
                          v-model="ct.ref_cia[2]"
                          :label="ct.ref_cia[1]"
                          :disabled="noEdit"
                        ></uiText>

                        <uiText
                          v-model="ct.ccont[2]"
                          :label="ct.ccont[1]"
                          :disabled="noEdit"
                          uCase
                        ></uiText>
                      </div>
                    </v-sheet>                   

                    <div class="cab">GRUPO</div>
                    <v-sheet v-bind="$cfg.styles.marco">
                      <div class="conflex">
                        <uiText
                          style="flex: 1 1 33%"
                          v-model="ct.tlf1[2]"
                          :label="ct.tlf1[1]"
                          :disabled="noEdit"
                        ></uiText>

                        <uiText
                          style="flex: 1 1 33%"
                          v-model="ct.tlf2[2]"
                          :label="ct.tlf2[1]"
                          :disabled="noEdit"
                        ></uiText>

                        <uiText
                          style="flex: 1 1 33%"
                          v-model="ct.tlf3[2]"
                          :label="ct.tlf3[1]"
                          :disabled="noEdit"
                        ></uiText>
                      </div>

                      <div class="conflex">
                        <uiText
                          style="flex: 1 1 33%"
                          v-model="ct.tlf4[2]"
                          :label="ct.tlf4[1]"
                          :disabled="noEdit"
                        ></uiText>

                        <uiText
                          style="flex: 1 1 33%"
                          v-model="ct.tlf5[2]"
                          :label="ct.tlf5[1]"
                          :disabled="noEdit"
                        ></uiText>

                        <uiText
                          style="flex: 1 1 33%"
                          v-model="ct.tlf6[2]"
                          :label="ct.tlf6[1]"
                          :disabled="noEdit"
                        ></uiText>
                      </div>

                      <div class="conflex">
                        <uiText
                          style="flex: 1 1 33%"
                          v-model="ct.fax1[2]"
                          :label="ct.fax1[1]"
                          :disabled="noEdit"
                        ></uiText>

                        <uiText
                          style="flex: 1 1 33%"
                          v-model="ct.fax2[2]"
                          :label="ct.fax2[1]"
                          :disabled="noEdit"
                        ></uiText>

                        <uiText
                          style="flex: 1 1 33%"
                          v-model="ct.fax3[2]"
                          :label="ct.fax3[1]"
                          :disabled="noEdit"
                        ></uiText>
                      </div>

                      <uiUrl
                        v-model="ct.url[2]"
                        :label="ct.url[1]"
                        :disabled="noEdit">
                      </uiUrl>
                     
                    </v-sheet>
                  </div>
                </div>
              </div>
            </v-sheet>


            <!-- Ventana de Componentes Extra  -->      
            <component      
              :is="componenteDinamico"
              syncUpdate
              :storeRaiz="storeName"
              :masterStore="storeName"              
              :readOnly="readOnly"
              tipoDocumento='2'
              @onEvent="$event.accion==6 || $event.accion=='salir'? componenteDinamico=null : ''">
            </component>

          </div>
        </template>
    </dualTemplate>
  </div>
</template>



<script>
  import { mixM } from "@/mixins/mixM.js";
  import baseHeader from "@/base/baseHeader";
  import baseBtraMto from "@/base/baseBtraMto";
  import baseBtraExtra from "@/base/baseBtraExtra";  
  import dualTemplate from "@/components/dualTemplate";
  import ctrlF from "@/components/ctrlF";  
  import commonDocsF from "@/components/commonDocsF.vue";
  import commonFrasF from "@/components/commonFrasF.vue";
  
  export default {
    mixins: [mixM],
    components: { 
      baseHeader, 
      baseBtraMto, 
      baseBtraExtra, 
      dualTemplate, 
      ctrlF,
      commonDocsF,
      commonFrasF
    },

    props: {},

    data() {
      return {
        stIni: {
          api: "ciasM",
          name:"ciasM",
          titulo:"Compañías Sucursales",  
          recordAccess:"local",
          mView:'',               // Módulo manteniniento asociado
          pView:[]                // Permisos del mantenimiento asociado
        },  
              
        itemsSitu:[]
      };
    },



    methods: {
      iniDataParticular() {
        console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");

        // añado botones extra
        this.btExtCfg.btnsAccion= [
          { "accion":'documentos', "btn": "docs" },
          { "accion":'facturas', "btn": "facturas" }
        ];
        
        // aray de situación
        this.itemsSitu=this.$store.state.datos_iniciales.ciasitu;
      },


      // COMPONENTES DINAMICOS (asignación)
      //
      documentos() {
        this.componenteDinamico= 'commonDocsF';
      },

      //
      facturas() {
        this.componenteDinamico= 'commonFrasF';        
      }

    }
  };
</script>
