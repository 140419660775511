<!-- Finder particular de Compañías Sucursales -->

<template>
  <div class="ciasF" v-if="loaded">

    <div style="display:flex;justify-content:center">
      <v-sheet v-if="!viewMtoOnly" :elevation="4">

        <!-- Cabecera -->
        <div class="cabecera">
          <baseHeader
            :cfg="$cfe.headers.finder"
            :titulo='$store.state[storeName].titulo'
            @onEvent="eventHeader">
          </baseHeader>
        </div>

        <!-- Contenido -->
        <div class="contenedor">

          <!-- <div v-html="info"></div> -->

          <div style="display:flex;align-items:center">
            <div v-if="tipoVista" class="columna" style="width:50rem">
              <div class="conflex" style="width:100%">
                <uiText
                  style="width:60%"
                  v-model="ct.cia[2]"
                  :label="ct.cia[1]"
                  @Enter="eventFiltro('buscar')">
                </uiText>

                <v-btn
                  v-bind="$cfe.btn.busca"
                  style="margin-left:5px"
                  @click="eventFiltro('buscar')">
                    <v-icon dark>{{ $cfe.btn.busca.icono }}</v-icon>
                </v-btn>

                <v-checkbox
                  class="ml-2"
                  style="width:20%"
                  v-bind="$checkbox"
                  v-model="ct.bajas[2]"
                  :label="ct.bajas[1]">
                </v-checkbox>

              </div>

              <div class="conflex">
                <ctrlF style="width:50%" :ct="ct.loc"></ctrlF>
                <ctrlF style="width:50%;padding-left:5px" :ct="ct.ciac"></ctrlF>
              </div>
            </div>
          </div>


          <!-- Grid -->
          <baseGridMD
            :store="storeName"
            :headers="headerGrid"
            :items-per-page="-1"
            dense
            @onEvent="execAccion">

              <!-- Slot botonera Top -->
              <template v-slot:gridTop="{}">
                <div style="padding:10px 0px 10px 0px">

                  <baseBtraMto
                    :perm="permMto"
                    :modulo="btGridCfg"
                    @onEvent="execAccion">
                  </baseBtraMto>

                </div>
              </template>

              <template v-slot:name="{ item }">
                <!-- <div :style="item.baja=='1' ?'color:red;':'' + 'font-weight:bold'">{{  item.name  }}</div> -->
                <div :style="item.baja=='1'||item.situ=='BAJ' ?'color:red;':''">{{  item.name  }}</div>
              </template>

              <!-- Slot campo acciones del Grid -->
              <template v-slot:acciones="{ item }">
                <baseBtraMto
                  :perm="permMto"
                  :modulo="btRowCfg"
                  @onEvent="execAccion({ ...$event, ...{item:item} })">
                </baseBtraMto>
              </template>
          </baseGridMD>
        </div>
      </v-sheet>


      <!-- Mto Compañías Sucursales  -->
      <component
        :is="componenteDinamico"
        :syncDisparo="syncDisparoSend"
        :syncAccion="syncAccionSend"
        syncUpdate
        :storeRaiz="storeName"
        :masterStore="storeName"
        :ctrlCt="ctrlCt"
        :readOnly="readOnly"
        :viewMtoOnly="viewMtoOnly"
        @onEvent="$emit('onEvent', $event)">
      </component>

    </div>
  </div>
</template>



<script>

  import { mixF } from "@/mixins/mixF.js";
  import baseHeader from "@/base/baseHeader";
  import baseGridMD from "@/base/baseGridMD";
  import baseBtraMto from "@/base/baseBtraMto";
  import ctrlF from "@/components/ctrlF";
  import ciasM from "@/components/ciasM";

  export default {
    mixins: [mixF],
    components: { baseHeader, baseGridMD, baseBtraMto, ctrlF, ciasM },
    props: {},

    data() {
      return {
        stIni: {
          api: "ciasF",
          titulo:"·Compañias Sucursales",
          name:"ciasF",
          mView:'ciasM',
          pView:[]
        },

      };
    },

    computed: {
      tipoVista() {
        if (this.tipoView!= 'FA' && this.tipoView!= 'FL') return true;
        return false;
      }
    },

    methods: {
      iniDataParticular() {
        console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");

        this.headers = [{
          name:[],
          fields:[
            { text: "Acciones", value: "acciones", sortable: false, width: "15%", slot:true },
            { text: "Compañía", value: "name", width: "40%", slot: true },
            { text: "Atlas", value: "atlas", width: "40%" },
            { text: "Situ", value: "situ", width: "5%" }
          ]}
        ];

        this.headerGrid= this.headerSet();

      },
    },



  };
</script>
